jQuery(function($) {
	$(window).resize(function() {
		if($(window).width() >= 1025) {
			$('#header > a').off('mouseenter').mouseenter(function() {
				var height = $('#header').outerHeight();
				if($('#header').hasClass('bottom')) {
					var height = height - 60;
				}
				$('#header').addClass('hover').find('.bg').css('height', height);
			});
			
			$('#header').off('mouseleave').mouseleave(function() {
				$('#header').removeClass('hover').find('.bg').css('height', '0');
				
				$('#header li.open').each(function() {
					$(this).removeClass('open');
					$(this).find('li').css('height', '0');
				});
			});
			
			$('#header li.menu-item-has-children li').each(function() {
				if($(this).height() != 0) {
					$(this).attr('data-height', ($(this).outerHeight())).css('height', '0');
				}
			});
		} else {
			$('#menu-toggle').off('click').click(function() {
				if($('#header').hasClass('open')) {
					$('#header').removeClass('open');
					$('body, html').css('overflow', 'auto');
					$('#header .social').fadeOut();
					setTimeout(function() {
						$('#header nav').stop().slideUp();
						
						$('#header li.open').each(function() {
							$(this).removeClass('open');
							$(this).find('li').css('height', '0');
						});
					}, 400);
				} else {
					$('#header').addClass('open');
					$('#header nav').stop().slideDown();
					$('body, html').css('overflow', 'hidden');
					setTimeout(function() {
						$('#header .social').fadeIn();
						
						$('#header li.menu-item-has-children li').each(function() {
							if(!$(this).attr('data-height')) {
								if($(this).height() != 0) {
									$(this).attr('data-height', $(this).outerHeight()).css('height', '0');
								}
							}
						});
					}, 400);
				}
			});
		}
	}).resize();
	
	$('#header li.menu-item-has-children').click(function(e) {
		if(!$(this).hasClass('open')) {
			e.preventDefault();
			
			$('#header li.open').each(function() {
				$(this).removeClass('open');
				$(this).find('li').css('height', '0');
			});
		
			$(this).addClass('open');
			$(this).find('li').each(function() {
				$(this).css('height', $(this).attr('data-height'));
			});
		}
	});

	//Tabbing through the nav menu
	$('body').keyup(function(e) {
		var code = e.keyCode || e.which;
		//if key is TAB
		if (code == '9') {
			//get new focused element
			var focusedElement = $(":focus");

			//if the focused element is in the header
			if (focusedElement.parents('header#header').length) {
				//open the header
				if (!$('#header').hasClass('hover')) {
					$('#header > a').trigger('mouseenter');
				}

				//if the focused element is a menu item with children...
				if(focusedElement.parent().hasClass('menu-item-has-children')) {
					$('#header li.open').each(function() {
						$(this).removeClass('open');
						$(this).find('li').css('height', '0');
					});
				
					$(focusedElement).parent().addClass('open');
					$(focusedElement).parent().find('li').each(function() {
						$(this).css('height', $(this).attr('data-height'));
					});
				} 
				//or if the focused element is a child of a menu item with children
				else if(focusedElement.parents().hasClass('menu-item-has-children')) {
					if(!$(focusedElement).parent().parent().parent().hasClass('open')) {
						$('#header li.open').each(function() {
							$(this).removeClass('open');
							$(this).find('li').css('height', '0');
						});
					
						$(focusedElement).parent().parent().parent().addClass('open');
						$(focusedElement).parent().parent().parent().find('li').each(function() {
							$(this).css('height', $(this).attr('data-height'));
						});
					}
				} 
				//if none of the above, close a menu item with children that has the open class.
				else {
					$('#header li.open').each(function() {
						$(this).removeClass('open');
						$(this).find('li').css('height', '0');
					});
				}
			} 
			//if focused element is not within the header, close the header menu
			else {
				if ($('#header').hasClass('hover')) {
					$('#header').removeClass('hover').find('.bg').css('height', '0');
					
					$('#header li.open').each(function() {
						$(this).removeClass('open');
						$(this).find('li').css('height', '0');
					});
				}
			}
		}
	});

	$('a[href="#register-interest"]').click(function() {
		$('html, body').css('overflow', 'hidden');
		
		$('.modal#register-interest').fadeIn();
	});
	
	$('.modal').click(function(e) {
		if($('.modal').is(e.target) || $('.close').is(e.target)) {
			$('.modal').fadeOut();
			
			$('html, body').css('overflow', 'auto');
		}
	});
	
	$('.block-faqs .faq a.arrow').click(function(e) {
		e.preventDefault();
		$(this).toggleClass('active').parent().find('.answer').stop().slideToggle();
	});
	
	var os = 540;
	if($('main').hasClass('no-image')) {
		os = 50;
	}
	
	var wp1 = new Waypoint({
		element: document.getElementById('page'),
		handler: function() {
			$('#header').toggleClass('top');
		},
		offset: os
	});
	
	var wp2 = new Waypoint({
		element: document.getElementById('footer'),
		handler: function() {
			$('#header').toggleClass('bottom');
		},
		offset: 'bottom-in-view'
	});
	
	var wp3 = new Waypoint({
		element: document.getElementById('footer'),
		handler: function() {
			$('#footer form').toggleClass('active');
		},
		offset: 'bottom-in-view'
	});
	
	$('#slider .slider').slick({
		fade: true,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 6000
	});
	
	$('.block-upcoming_events .slider').slick({
		fade: true,
		arrows: false,
		dots: true,
		autoplay: true,
		autoplaySpeed: 3000
	});

	$('.select .arrow').click(function() {
		$(this).parent().find('.dropdown').toggleClass('open');
	});
})